import React from "react"
import L from "leaflet"
import { GeoJSON, LayerGroup, useMap } from "react-leaflet"

import VesselTable from "./VesselTable"
import RouteHelper from "../../utils/routeHelper"
import dayjs from "dayjs"

export default function VesselIcon({
  vessels,
  markedVesselNames,
  setCenterLatLonMap,
  setMarkedVesselNames,
  setOpenDrawer,
  setOpenVesselList,
  isPaperOpen,
  setIsPaperOpen,
  selectedVessel,
  setSelectedVessel,
}) {
  const map = useMap()

  const handleMarkerClick = (vessel) => {
    setIsPaperOpen(true)
    setSelectedVessel(vessel)
  }

  return vessels === null ? null : (
    <>
      <LayerGroup>
        {vessels.map((vessel) => {
          let vesselPoints = vessel.points
          return vesselPoints.geojson.features === null || vesselPoints.geojson.features.length === null ? null : (
            <GeoJSON
              // key muss vessels und markedVesselNames enthalten, eventuell mit useMemo verhindern
              // key={`VesselIcon-${routePoints.routeId}-${vessels.length}-${markedVesselNames.length}-${sliderPosition.label}`}
              key={JSON.stringify(vesselPoints.geojson.features) + JSON.stringify(markedVesselNames)}
              data={vesselPoints.geojson}
              style={{
                fillColor: RouteHelper.findRouteColor(vesselPoints.routeType),
                color: RouteHelper.findRouteColor(vesselPoints.routeType),
                weight: 1,
              }}
              pointToLayer={(feature, latlon) => {
                // console.log(vesselPoints.vesselName, vesselPoints)
                // if no analyse available or low distance render CircleIcon
                if (!feature.properties.analyse || feature.properties.analyse.is_low_distance) {
                  return RouteHelper.renderCircleIcon({
                    latlon,
                    vesselPoints,
                    setCenterLatLonMap,
                    setMarkedVesselNames,
                    setOpenDrawer,
                    setOpenVesselList,
                    map,
                    handleMarkerClick: () => handleMarkerClick(vesselPoints), // passing vessel data
                  })
                } else {
                  return RouteHelper.renderRotatedIcon({
                    vesselPoints,
                    markedVesselNames,
                    setCenterLatLonMap,
                    setMarkedVesselNames,
                    setOpenDrawer,
                    setOpenVesselList,
                    map,
                    handleMarkerClick: () => handleMarkerClick(vesselPoints), // passing vessel data
                  })
                }
              }}
              onEachFeature={(feature, layer) => RouteHelper.applyVesselPopup({ vesselPoints, feature, layer })}
            />
          )
        })}
      </LayerGroup>
      {isPaperOpen && selectedVessel && (
        <VesselTable
          selectedVessel={selectedVessel}
          setIsPaperOpen={setIsPaperOpen}
          setSelectedVessel={setSelectedVessel}
        />
      )}
    </>
  )
}
