import * as turf from "@turf/turf" // GIS/GeoJSON library
import * as React from "react"
import { useState, useEffect, useMemo } from "react"

import { styled, useTheme } from "@mui/material/styles"
import { Box, IconButton, Toolbar, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import MuiDrawer from "@mui/material/Drawer"
import MuiAppBar from "@mui/material/AppBar"

import "leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, ZoomControl, LayersControl, LayerGroup } from "react-leaflet"

import { rotatedMarker } from "leaflet-marker-rotation" // import in L !!!, wird unten benutzt!!!
import "leaflet-polylinedecorator" // leaflet-marker-rotation und leaflet-polylinedecorator verändern beide rotationAngle!!!!! https://github.com/bbecquet/Leaflet.PolylineDecorator/issues/94
import { NmScale } from "@marfle/react-leaflet-nmscale"

import DrawerList from "./components/drawer/DrawerList"
import MouseCoordinates from "./components/map/MouseCoordinates"
import MinimapControl from "./components/map/MinimapControl"
import MyLatlngGraticule from "./components/plugins/MyLatlngGraticule"
import MyTerminator from "./components/plugins/MyTerminator"
import MyLeafletVelocity from "./components/plugins/MyLeafletVelocity"
import MyPolylineMeasure from "./components/plugins/MyPolylineMeasure"
import MyMapEvent from "./components/map/MyMapEvent"
import RecenterAutomaticallyMap from "./components/map/RecenterAutomaticallyMap"
import Eca from "./components/gis/eca"
import Highrisk from "./components/gis/highRisk"
import Loadline from "./components/gis/loadline"
import ExclusiveEconomicZone from "./components/gis/exclusiveEconomicZone"
import ExtendedContinentalShelf from "./components/gis/extendedContinentalShelf"
import WdpaPoint from "./components/gis/wdpaPoint"
import WdpaPolygon from "./components/gis/wdpaPolygon"
import Port from "./components/gis/port"
import Marina from "./components/gis/marina"
import RouteController from "./components/route/RouteController"
import VesselController from "./components/vessel/VesselController"
import GeoServerController from "./components/weatherlayers/GeoServerController"
import VoyagePlanner from "./components/voyagePlanner/VoyagePlannerController"
import VoyageTable from "./components/tables/voyageTable"
import PathfinderController from "./components/voyagePlanner/PathfinderController"

import countriesGeoJson from "./data/countries.json"
import logo from "./assets/images/WetterWeltIcon.png"

import { useFetchWaypointsGeojson } from "./hooks/useFetchWaypointsGeojson"
import { useAnalyseRealRoutes } from "./hooks/useAnalyseRealRoutes"
import { useFilterMarkedRoutes } from "./hooks/useFilterMarkedRoutes"
import { useAnalysePlanSuggestRoutes } from "./hooks/useAnalysePlanSuggestRoutes"

import { RouteAnalyser, Route } from "./modules/route_analyse/RouteAnalyser2"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { useFetchGeoServer } from "./hooks/useFetchGeoServer"
dayjs.extend(utc)

const availableModels = ["GFS", "ICON", "COP", "NWW3", "hurricane_data"]
const drawerWidth = 275
const appBarHeight = 66
const closedDrawerWidth = (theme) => `calc(${theme.spacing(7)} + 1px)`

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: closedDrawerWidth(theme),
  [theme.breakpoints.up("sm")]: {
    width: closedDrawerWidth(theme),
  },
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#004f7d", // Set the background color of the AppBar
  height: appBarHeight,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "openDrawer",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  backgroundColor: "#004f7d", // Set the background color of the AppBar
}))

export default function MainComponent({ logout }) {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openHudList, setOpenHudList] = useState(false)
  const [openGisList, setOpenGisList] = useState(false)
  const [openVesselList, setOpenVesselList] = useState(false)
  const [openVoyagePlannerList, setOpenVoyagePlannerList] = useState(false)
  const [openForecastList, setOpenForecastList] = useState(false)
  const [openWMSList, setOpenWMSList] = useState(false)
  const [openGeolocationList, setOpenGeolocationList] = useState(false)

  const initialZoom = 3
  const [zoomLevel, setZoomLevel] = useState(initialZoom)

  // TODO zoomed???
  const [dragged, setDragged] = useState(false) // switch/event callback!!!
  const [resized, setResized] = useState(0) // counter/event callback!!!
  const [mapBounds, setMapBounds] = useState(null)
  // DateTimePicker values
  const [fromPicker, setFromPicker] = useState(dayjs.utc().subtract(3, "day").startOf("day"))
  const [toPicker, setToPicker] = useState(dayjs.utc().add(3, "day").endOf("day"))
  const [hasDateChanged, setHasDateChanged] = useState(false)
  const [sliderPosition, setSliderPosition] = useState({ value: [], label: [] })
  const [etaButtonReported, setEtaButtonReported] = useState(false)

  const [switchReported, setSwitchReported] = useState(false)
  const [switchPlan, setSwitchPlan] = useState(false)
  const [switchSuggest, setSwitchSuggest] = useState(false)
  const [switchForecast, setSwitchForecast] = useState(false)
  const [switchReanalyse, setSwitchReanalyse] = useState(false)
  const [switchVoyagePlanner, setSwitchVoyagePlanner] = useState(false)

  const [fetchingForecast, setFetchingForecast] = useState(false)
  const [fetchingReanalyse, setFetchingReanalyse] = useState(false)

  const [wodTiff, setWodTiff] = useState(null)
  const [selectedModel, setSelectedModel] = useState("A_GFS")
  const [selectedParams, setSelectedParams] = useState(["T0"])

  // State to manage checked items
  const [checkedWMSLayers, setCheckedWMSLayers] = useState({})

  const [voyagePlannerPoints, setVoyagePlannerPoints] = useState(turf.featureCollection([])) // State to store points
  const [voyageActive, setVoyageActive] = useState(false) // State to manage if voyage is active
  const [pathfinderRoutes, setPathfinderRoutes] = useState({})
  const [calculatorDepartureTime, setCalculatorDepartureTime] = useState(dayjs().add(1, "day"))
  const [calculatorBasespeed, setCalculatorBasespeed] = useState(15)
  const [etaResponse, setEtaResponse] = useState(false)

  // https://stackoverflow.com/questions/65322670/change-center-position-of-react-leaflet-map
  const [centerLatLonMap, setCenterLatLonMap] = React.useState([10, 10])
  const [markedVesselNames, setMarkedVesselNames] = React.useState([]) // TODO umbenennen in autocompleteVesselInfos
  const [isPaperOpen, setIsPaperOpen] = useState(false)
  const [selectedVessel, setSelectedVessel] = useState(null)

  const [toogleButtonsOnOff, setToogleButtonsOnOff] = React.useState(() => [
    "Minimap",
    "NautiScale",
    "ZoomControl",
    "Attribution",
    "LatLon",
    "Terminator",
    "Graticule",
    "PolylineMeasure", //,'LeafletVelocity','Pixi','Windflow', // -> ToggleButtonsHud.js
    "RealRoute",
    "PlanRoute",
    "SuggestRoute", // -> ToggleButtonsVesselLayers.js
    //"ECA",
    //"HighRisk", // 'WDPA Point','Loadline','Port','City','Country','River','Lake','Canal',... // -> ToggleButtonsGeoVectorLayers.js
  ])
  const handleToogleButtonsOnOff = (event, newToogleButtonsOnOff) => {
    setToogleButtonsOnOff(newToogleButtonsOnOff)
  }

  // https://stackoverflow.com/questions/73443267/lastest-version-of-reactjs-and-leaflet-shapefile-not-work-only-display-blank-pa
  const [map, setMap] = useState(null)
  useEffect(() => {
    if (!map) return
  }, [map])

  // {/* ---fetch tritonWaypointGeojsons Data -------------------------------------------------------------- */}
  const { tritonWaypointsGeojson: tritonRealWaypointsGeojson, loadingState: fetchingReported } =
    useFetchWaypointsGeojson(fromPicker, toPicker, "real", hasDateChanged, setHasDateChanged)
  const { tritonWaypointsGeojson: tritonPlanWaypointsGeojson, loadingState: fetchingPlanned } =
    useFetchWaypointsGeojson(fromPicker, toPicker, "plan", hasDateChanged, setHasDateChanged)
  // {/* ---analyse and filter tritonWaypointGeojsons Data -------------------------------------------------------------- */}
  const analysedRealRoutes = useAnalyseRealRoutes(fromPicker, toPicker, tritonRealWaypointsGeojson, toogleButtonsOnOff)
  const analysedPlanRoutes = useAnalysePlanSuggestRoutes(
    fromPicker,
    toPicker,
    tritonPlanWaypointsGeojson,
    analysedRealRoutes
  )
  const filteredRealRoutes = useFilterMarkedRoutes(analysedRealRoutes, markedVesselNames)
  const filteredPlanRoutes = useFilterMarkedRoutes(analysedPlanRoutes, markedVesselNames)
  const [vesselRealRoutes, setVesselRealRoutes] = useState(filteredRealRoutes)
  const [vesselPlanRoutes, setVesselPlanRoutes] = useState(filteredPlanRoutes)
  useEffect(() => {
    setVesselRealRoutes(filteredRealRoutes)
  }, [filteredRealRoutes])

  useEffect(() => {
    setVesselPlanRoutes(filteredPlanRoutes)
  }, [filteredPlanRoutes])

  useEffect(() => {
    if (vesselRealRoutes.length === 1) {
      setEtaButtonReported(true)
    } else {
      setEtaButtonReported(false)
    }
  }, [vesselRealRoutes])

  // const analysedSuggestRoutes = useAnalysePlanSuggestRoutes(
  //   fromPicker,
  //   toPicker,
  //   tritonSuggestWaypointsGeojson,
  //   analysedRealRoutes
  // )
  let vesselSuggestRoutes //= useFilterMarkedRoutes(analysedSuggestRoutes, markedVesselNames)

  // {/* --- fetch GeoServer Data*/}
  let geoServerCapabilities = useFetchGeoServer({
    availableModels,
  })

  // {/* --- Component/drawer fuer <NavbarAccordion> --------------------------------------------------- */}
  const theme = useTheme()
  // const drawerClosedWidthValue = closedDrawerWidth(theme)

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }
  const handleDrawerClose = () => {
    setOpenDrawer(false)
    setOpenHudList(false)
    setOpenGisList(false)
    setOpenVesselList(false)
    setOpenVoyagePlannerList(false)
    setOpenForecastList(false)
    setOpenWMSList(false)
    setOpenGeolocationList(false)
  }

  const drawer = (toogleButtonsOnOff, setToogleButtonsOnOff, handleToogleButtonsOnOff) => {
    return (
      <Box>
        {/* <DrawerHeader></DrawerHeader> */}
        {/* <Divider /> */}
        {toogleButtonsOnOff === null ? null : (
          <DrawerList
            tritonRealWaypointsGeojson={tritonRealWaypointsGeojson}
            vesselPlanRoutes={vesselPlanRoutes}
            vesselRealRoutes={vesselRealRoutes}
            toogleButtonsOnOff={toogleButtonsOnOff}
            setToogleButtonsOnOff={setToogleButtonsOnOff}
            handleToogleButtonsOnOff={handleToogleButtonsOnOff}
            setCenterLatLonMap={setCenterLatLonMap}
            markedVesselNames={markedVesselNames}
            setMarkedVesselNames={setMarkedVesselNames}
            switchReported={switchReported}
            setSwitchReported={setSwitchReported}
            switchPlan={switchPlan}
            setSwitchPlan={setSwitchPlan}
            switchSuggest={switchSuggest}
            setSwitchSuggest={setSwitchSuggest}
            switchForecast={switchForecast}
            setSwitchForecast={setSwitchForecast}
            switchReanalyse={switchReanalyse}
            setSwitchReanalyse={setSwitchReanalyse}
            fromPicker={fromPicker}
            setFromPicker={setFromPicker}
            toPicker={toPicker}
            setToPicker={setToPicker}
            hasDateChanged={hasDateChanged}
            setHasDateChanged={setHasDateChanged}
            etaButtonReported={etaButtonReported}
            setEtaButtonReported={setEtaButtonReported}
            drawerWidth={drawerWidth}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            openHudList={openHudList}
            setOpenHudList={setOpenHudList}
            openGisList={openGisList}
            setOpenGisList={setOpenGisList}
            openVesselList={openVesselList}
            setOpenVesselList={setOpenVesselList}
            openWMSList={openWMSList}
            setOpenWMSList={setOpenWMSList}
            openVoyagePlanner={openVoyagePlannerList}
            setOpenVoyagePlanner={setOpenVoyagePlannerList}
            openForecastList={openForecastList}
            setOpenForecastList={setOpenForecastList}
            openGeolocationList={openGeolocationList}
            setOpenGeolocationList={setOpenGeolocationList}
            logout={logout}
            appBarHeight={appBarHeight}
            fetchingReported={fetchingReported}
            fetchingForecast={fetchingForecast}
            fetchingReanalyse={fetchingReanalyse}
            sliderPosition={sliderPosition}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            selectedParams={selectedParams}
            setSelectedParams={setSelectedParams}
            voyagePlannerPoints={voyagePlannerPoints}
            setVoyagePlannerPoints={setVoyagePlannerPoints}
            voyageActive={voyageActive}
            setVoyageActive={setVoyageActive}
            switchVoyagePlanner={switchVoyagePlanner}
            setSwitchVoyagePlanner={setSwitchVoyagePlanner}
            geoServerCapabilities={geoServerCapabilities.geoServerCapabilities}
            checkedWMSLayers={checkedWMSLayers}
            setCheckedWMSLayers={setCheckedWMSLayers}
            setPathfinderRoutes={setPathfinderRoutes}
            calculatorDepartureTime={calculatorDepartureTime}
            setCalculatorDepartureTime={setCalculatorDepartureTime}
            calculatorBasespeed={calculatorBasespeed}
            setCalculatorBasespeed={setCalculatorBasespeed}
            etaResponse={etaResponse}
            setEtaResponse={setEtaResponse}
          />
        )}
      </Box>
    )
  }

  const MemoizedRouteController = useMemo(
    () => (
      // <Profiler id="RouteController" onRender={onRenderCallback}>
      <RouteController
        mapBounds={mapBounds}
        vesselRealRoutes={vesselRealRoutes}
        vesselPlanRoutes={vesselPlanRoutes}
        vesselSuggestRoutes={vesselSuggestRoutes}
        switchReported={switchReported}
        switchPlan={switchPlan}
        switchSuggest={switchSuggest}
        switchForecast={switchForecast}
        switchReanalyse={switchReanalyse}
        fromPicker={fromPicker}
        toPicker={toPicker}
        setFetchingForecast={setFetchingForecast}
        setFetchingReanalyse={setFetchingReanalyse}
        drawerWidth={drawerWidth}
        openDrawer={openDrawer}
        resized={resized}
      />
    ),
    // </Profiler>
    [
      vesselRealRoutes,
      vesselPlanRoutes,
      vesselSuggestRoutes, //wird erstmal nicht benötigt, da von mapBounds übernommen JK 4.9.23
      switchReported,
      switchPlan,
      switchSuggest,
      switchForecast,
      switchReanalyse,
      fromPicker,
      toPicker,
      mapBounds,
      openDrawer,
      resized,
    ]
  )

  const MemoizedVesselController = useMemo(
    () => (
      // <Profiler id="VesselController" onRender={onRenderCallback}>
      <VesselController
        tritonRealWaypointsGeojson={tritonRealWaypointsGeojson}
        vesselRealRoutes={vesselRealRoutes}
        vesselPlanRoutes={vesselPlanRoutes}
        vesselSuggestRoutes={vesselSuggestRoutes}
        pathfinderRoutes={pathfinderRoutes}
        markedVesselNames={markedVesselNames}
        switchReported={switchReported}
        switchPlan={switchPlan}
        switchSuggest={switchSuggest}
        fromPicker={fromPicker}
        toPicker={toPicker}
        setCenterLatLonMap={setCenterLatLonMap}
        setMarkedVesselNames={setMarkedVesselNames}
        setOpenDrawer={setOpenDrawer}
        setOpenVesselList={setOpenVesselList}
        drawerWidth={drawerWidth}
        openDrawer={openDrawer}
        isPaperOpen={isPaperOpen}
        setIsPaperOpen={setIsPaperOpen}
        selectedVessel={selectedVessel}
        setSelectedVessel={setSelectedVessel}
        resized={resized}
        sliderPosition={sliderPosition}
        setSliderPosition={setSliderPosition}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        selectedParams={selectedParams}
        setSelectedParams={setSelectedParams}
        geoServerCapabilities={geoServerCapabilities.geoServerCapabilities}
      />
    ),
    // </Profiler>
    [
      vesselRealRoutes,
      vesselPlanRoutes,
      vesselSuggestRoutes,
      pathfinderRoutes,
      switchReported,
      switchPlan,
      switchSuggest,
      switchForecast,
      switchReanalyse,
      openDrawer,
      isPaperOpen,
      selectedVessel,
      resized,
      sliderPosition,
      selectedModel,
      selectedParams,
      geoServerCapabilities,
    ]
  )

  // const MemoizedLayerController = useMemo(
  //   () => (
  //     <LayerController
  //       wodTiff={wodTiff}
  //       toogleButtonsOnOff={toogleButtonsOnOff}
  //       zoomLevel={zoomLevel}
  //       mapBounds={mapBounds}
  //     />
  //   ),
  //   [wodTiff, toogleButtonsOnOff, mapBounds]
  // )

  const MemoizedGeoServerController = useMemo(() => {
    if (sliderPosition.label.length === 0) {
      return null
    }
    return (
      <GeoServerController
        sliderPosition={sliderPosition}
        checkedWMSLayers={checkedWMSLayers}
        drawerWidth={drawerWidth}
        openDrawer={openDrawer}
        resized={resized}
      />
    )
  }, [sliderPosition, checkedWMSLayers, openDrawer, zoomLevel, resized])

  // {/* --- react/mui/renderer ---------------------------------------------------------------------------- */}
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" open={openDrawer} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 3, pl: 0.6, ...(openDrawer && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton>
            <img src={logo} style={{ height: "50px" }} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
            // style={{ fontWeight: "bold" }}
          >
            <span style={{ fontWeight: "bold" }}>METEO</span>
            <span style={{ fontWeight: "100" }}> TRACKER</span>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h7">Early Access Version 0.8.6</Typography>
        </Toolbar>
      </AppBar>
      {/* --- MUI/HUD/Drawer ----------------------------------------------------------------------------- */}
      <Drawer variant="permanent" open={openDrawer}>
        <DrawerHeader sx={{ height: appBarHeight, backgroundColor: "#004f7d" }}>
          <IconButton sx={{ color: "white" }} onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            height: `calc(100vh - ${appBarHeight}px)`,
            width: "100%",
          }}
        >
          {drawer(toogleButtonsOnOff, setToogleButtonsOnOff, handleToogleButtonsOnOff)}
        </Box>
      </Drawer>
      {/* --- Leaflet/MapContainer ----------------------------------------------------------------------------- */}
      <MapContainer
        // rc 2023-02-23 Performance besser??? preferCanvas={true} funktioniert nicht mit leaflet-hotline.js JK 7.3.23
        //preferCanvas={true} //funktioniert mit leaflet-hotline-react JK 15.11.23
        //updateWhenZooming={false} -> L.gridLayer
        //updateWhenIdle={true} -> L.gridLayer

        //setMinZoom={6} zoomDelta={0.25} zoomSnap={0}
        center={[0, 0]} // {[59.505, -30.09]} london
        zoom={initialZoom}
        // maxZoom={13} // https://github.com/PaulLeCam/react-leaflet/issues/296
        minZoom={3} // nicht viele Maps nebeneinander, https://github.com/PaulLeCam/react-leaflet/issues/296
        // maxBounds={[
        //   [85, -360],
        //   [-85, 360],
        // ]} // https://stackoverflow.com/questions/3733227/zooming-and-panning-a-map-to-show-all-markers
        // maxBounds={bounds}
        // zoomSnap={1} // Ensure the zoom snaps to whole numbers
        // zoomDelta={1} // Only allow whole number zoom levels
        boxZoom={true}
        scrollWheelZoom={true}
        doubleClickZoom={true}
        // dragging={isDraggable}
        // dragging={false}
        animate={true} // rc 2023-02-23 Performance Problem?
        easeLinearity={0.35} // rc 2023-02-23 Performance Problem?
        style={{ height: "100vh", width: "100%" }}
        ref={setMap}
        zoomControl={false} // https://codesandbox.io/embed/github/colbyfayock/egghead-code-examples/tree/master/change-map-zoom-control-location-and-icons-in-react-leaflet?fontsize=14&hidenavigation=1&theme=dark
        //attributionControl={true}
      >
        <MyMapEvent
          setZoomLevel={setZoomLevel}
          setDragged={setDragged}
          setResized={setResized}
          setMapBounds={setMapBounds}
        />
        {!toogleButtonsOnOff.includes("NautiScale") ? null : (
          <div>
            <NmScale position="bottomright" />
          </div>
        )}
        {/* --- LayersControl.BaseLayer ------------------------------------------------------------------- */}
        <LayersControl position="bottomright">
          {/* <LayersControl.BaseLayer name="No Map">
            <TileLayer attribution={!toogleButtonsOnOff.includes("Attribution") ? null : ""} url="" />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Countries (vector/local)">
            <LayerGroup>
              <GeoJSON
                style={{
                  fillColor: "green",
                  color: "grey",
                  weight: 1,
                  //fillOpacity:"1.0",opacity:"1.0"
                }} // style alternative 2
                data={countriesGeoJson.features}
              />
            </LayerGroup>
          </LayersControl.BaseLayer> */}

          <LayersControl.BaseLayer name="Esri.WorldStreetMap">
            <LayerGroup id="layergroupEsriWorldStreetMapEnglish">
              <TileLayer
                attribution={!toogleButtonsOnOff.includes("Attribution") ? null : "Esri.WorldStreetMap"} // Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                maxZoom={19} // https://github.com/PaulLeCam/react-leaflet/issues/296
              />
            </LayerGroup>
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Esri.WorldGrayMap" checked>
            <LayerGroup id="layergroupEsriWorldGrayMapEnglish">
              <TileLayer
                attribution={!toogleButtonsOnOff.includes("Attribution") ? null : "Esri.WorldGrayMap"} // Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}@2x"
                maxZoom={19} // https://github.com/PaulLeCam/react-leaflet/issues/296
                // detectRetina={true}
              />
            </LayerGroup>
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Esri.WorldImagery">
            <LayerGroup id="layergroupEsriWorldImagery">
              <TileLayer
                attribution={!toogleButtonsOnOff.includes("Attribution") ? null : "Esri.WorldImagery"} // Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                maxZoom={19}
              />
            </LayerGroup>
          </LayersControl.BaseLayer>

          {/* <LayersControl.BaseLayer name="OpenStreetMap (german)">
            <LayerGroup id="layergroupOpenStreetMapDeutsch">
              <TileLayer
                attribution={
                  !toogleButtonsOnOff.includes("Attribution")
                    ? null
                    : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                }
                url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
                maxZoom={20}
              />
            </LayerGroup>
          </LayersControl.BaseLayer> */}

          {/* <LayersControl.BaseLayer name="OpenStreetMap (internat.)">
            <LayerGroup id="layergroupOpenStreetMapMapnik">
              <TileLayer
                attribution={
                  !toogleButtonsOnOff.includes("Attribution")
                    ? null
                    : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                }
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayerGroup>
          </LayersControl.BaseLayer> */}
          {/* 
          <LayersControl.BaseLayer name="Blue Marble">
            <WMSTileLayer
              attribution={!toogleButtonsOnOff.includes("Attribution") ? null : "&copy; <a>Bathymetry/Blue Marble</a>"}
              url="https://maps.dwd.de/geoserver/dwd/wms"
              layers={["dwd:bluemarble"]}
            />
            </LayersControl.BaseLayer>
            
            <LayersControl.BaseLayer name="Bathymetry/Gebco">
            <WMSTileLayer
            attribution={
              !toogleButtonsOnOff.includes("Attribution")
              ? null
              : '&copy; <a href="http://www.gebco.net">GEBCO_2020</a>'
            }
            url="https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?"
            layers="GEBCO_LATEST"
            format="image/png"
            transparent={true} // hintergrund zeichen bei alpha-transparenz-bereichen dieses bildes
            //opacity="0.5"
            />
          </LayersControl.BaseLayer> */}

          {/* --- LayersControl.Overlay ------------------------------------------------------------------- */}

          <LayersControl.Overlay name="OpenSeaMap" unchecked>
            <LayerGroup id="layergroup_openseamap">
              <TileLayer
                attribution={
                  !toogleButtonsOnOff.includes("Attribution")
                    ? null
                    : '&copy; <a href="https://www.openseamap.org/">OpenSeaMap</a>'
                }
                url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
              />
            </LayerGroup>
          </LayersControl.Overlay>

          {/* <LayersControl.Overlay name="DWD Weather Warnings">
            <LayerGroup id="layergroupDwdWarn">
              <WMSTileLayer
                id="layerDwd1"
                url="https://maps.dwd.de/geoserver/dwd/wms"
                layers="dwd:Warngebiete_Kreise"
                format="image/png"
                transparent={true}
              />
              <WMSTileLayer
                id="layerDwd2"
                url="https://maps.dwd.de/geoserver/dwd/wms"
                layers="dwd:Warnungen_Gemeinden_vereinigt"
                format="image/png"
                transparent={true}
              />
            </LayerGroup>
          </LayersControl.Overlay>

          {!toogleButtonsOnOff.includes("LeafletVelocity") ? null : (
            <LayersControl.Overlay name="MyLeafletVelocity">
              <LayerGroup id="MyLeafletVelocity">
                <MyLeafletVelocity />
              </LayerGroup>
            </LayersControl.Overlay>
          )} */}

          {/* https://react-leaflet.js.org/docs/api-components/ unter MediaOverlay behavior */}
          {/* <LayersControl.Overlay name="ImageOverlay New York (Test)"> */}
          {/* <ImageOverlay
            url="http://www.lib.utexas.edu/maps/historical/newark_nj_1922.jpg"
            bounds={new L.LatLngBounds([20.712216, -54.12544], [40.773941, -74.22655])}
            opacity={1.0}
            zIndex={1010}
          /> */}
          {/* </LayersControl.Overlay> */}

          {/* https://www.tabnine.com/code/javascript/classes/react-leaflet/VideoOverlay */}
          {/* <LayersControl.Overlay name="VideoOverlay Mexico (Test)">
            <VideoOverlay
              bounds={[
                [32, -130],
                [13, -100],
              ]}
              play={true}
              url="https://www.mapbox.com/bites/00188/patricia_nasa.webm"
            />
          </LayersControl.Overlay> */}
          {MemoizedGeoServerController}
          {/* <LayersControl.Overlay name="GeoServer">
            <WMSTileLayer
              key={`${layerName}_${styleName}_${formattedTimestamp}`}
              url="http://geoserver-p.wetterwelt.intern:8080/geoserver/chartworld/wms"
              layers="chartworld:T"
              format="image/png"
              transparent={true}
              opacity={0.5}
              styles={styleName}
              params={{ TIME: formattedTimestamp }}
            />
          </LayersControl.Overlay> */}
        </LayersControl>
        {/* --- Leaflet.Plugins ------------------------------------------------------------------------ */}
        {!toogleButtonsOnOff.includes("Terminator") ? null : <MyTerminator />}
        {!toogleButtonsOnOff.includes("ZoomControl") ? null : (
          <ZoomControl position="bottomright" zoomInText="+" zoomOutText="-" />
        )}
        {!toogleButtonsOnOff.includes("Graticule") ? null : <MyLatlngGraticule />}
        {!toogleButtonsOnOff.includes("Minimap") ? null : <MinimapControl position="topright" />}{" "}
        {!toogleButtonsOnOff.includes("LatLon") ? null : (
          <div key={"MouseCoordinates outer div"} className={`leaflet-top leaflet-right mouse_coordinates-offset`}>
            <MouseCoordinates key={"MouseCoordinates"} />
          </div>
        )}
        {!toogleButtonsOnOff.includes("PolylineMeasure") ? null : <MyPolylineMeasure />}
        {/* --------------------------------------------------------------------------------------- */}
        <Eca toogleButtonsOnOff={toogleButtonsOnOff} />
        <Highrisk toogleButtonsOnOff={toogleButtonsOnOff} />
        <Port toogleButtonsOnOff={toogleButtonsOnOff} zoomLevel={zoomLevel} dragged={dragged} resized={resized} />
        {/* <Loadline fetch_url={GEO_URL} toogleButtonsOnOff={toogleButtonsOnOff} /> */}
        {/* <Marina fetch_url={GEO_URL} toogleButtonsOnOff={toogleButtonsOnOff} />
        <WdpaPoint fetch_url={GEO_URL} toogleButtonsOnOff={toogleButtonsOnOff} />
        <WdpaPolygon fetch_url={GEO_URL} toogleButtonsOnOff={toogleButtonsOnOff} />
        <ExclusiveEconomicZone fetch_url={GEO_URL} toogleButtonsOnOff={toogleButtonsOnOff} />
        <ExtendedContinentalShelf fetch_url={GEO_URL} toogleButtonsOnOff={toogleButtonsOnOff} /> */}
        {/* --------------------------------------------------------------------------------------- */}
        <RecenterAutomaticallyMap centerLatLonMap={centerLatLonMap} />
        {/* {MemoizedLayerController} */}
        {MemoizedRouteController}
        {MemoizedVesselController}
        <VoyagePlanner
          voyagePlannerPoints={voyagePlannerPoints}
          setVoyagePlannerPoints={setVoyagePlannerPoints}
          toogleButtonsOnOff={toogleButtonsOnOff}
          voyageActive={voyageActive}
          setVoyageActive={setVoyageActive}
        />
        {/* <VoyageTable
          appBarHeight={appBarHeight}
          switchVoyagePlanner={switchVoyagePlanner}
          setSwitchVoyagePlanner={setSwitchVoyagePlanner}
          voyagePlannerPoints={voyagePlannerPoints}
          setVoyagePlannerPoints={setVoyagePlannerPoints}
          vesselPlanRoutes={vesselPlanRoutes}
          setVesselPlanRoutes={setVesselPlanRoutes}
          voyageActive={voyageActive}
          setVoyageActive={setVoyageActive}
          drawerWidth={drawerWidth}
          openDrawer={openDrawer}
        /> */}
        <PathfinderController
          pathfinderRoutes={pathfinderRoutes}
          setPathfinderRoutes={setPathfinderRoutes}
          mapBounds={mapBounds}
          sliderPosition={sliderPosition}
          markedVesselNames={markedVesselNames}
          setCenterLatLonMap={setCenterLatLonMap}
          setMarkedVesselNames={setMarkedVesselNames}
          setOpenDrawer={setOpenDrawer}
          setOpenVesselList={setOpenVesselList}
          map={map}
          calculatorDepartureTime={calculatorDepartureTime}
          calculatorBasespeed={calculatorBasespeed}
          setEtaResponse={setEtaResponse}
        />
      </MapContainer>
    </Box>
  )
}
