import React, { useEffect, useRef } from "react"

import L from "leaflet"
import { LayerGroup, useMap } from "react-leaflet"

import { Box } from "@mui/material"

// import CurrentVectors from "../meteo/currentVectors"
import CurrentVectors from "../meteo/newCurrentVectors"
// import WindBarbs from "../meteo/windBarbs"
import WindBarbs from "../meteo/newWindBarbs"
// import WaveDirection from "../meteo/waveDirection"
import WaveDirection from "../meteo/newWaveDirection"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

function createHotlinePalette(jsonObj) {
  const hotlinePalette = {}
  const step = 1 / jsonObj.length
  // Loop through each object in the JSON array
  for (let i = 0; i < jsonObj.length; i++) {
    // Extract the values and color properties
    const obj = jsonObj[i]
    const values = obj.values
    const color = obj.color
    if (i * step < 1) {
      hotlinePalette[i * step + step / 2] = color
    } else if (i * step >= 1) {
      hotlinePalette[i * step] = color
    }
  }
  return hotlinePalette
}

function MyHotline({ colorObject, selectedParameter, positions, map }) {
  const hotlineRef = useRef(null)

  useEffect(() => {
    if (colorObject && selectedParameter && positions) {
      const leafletHotlineOptions = {
        weight: 20,
        min: colorObject[0].values[0],
        max: colorObject[colorObject.length - 1].values[0],
        contourWidth: 0,
        palette: createHotlinePalette(colorObject),
      }

      // If there is an existing hotline, remove it
      if (hotlineRef.current) {
        hotlineRef.current.removeFrom(map)
      }
      // Add the new hotline and store a reference to it
      hotlineRef.current = L.hotline(positions, leafletHotlineOptions)
      // Manually add the hotline to the desired pane
      hotlineRef.current.addTo(map)
    }

    // Clean up by removing the hotline when the component unmounts
    return () => {
      if (hotlineRef.current) {
        hotlineRef.current.removeFrom(map)
      }
    }
  }, [colorObject, selectedParameter, positions, map]) // Depend on all the variables used inside useEffect

  return null
}

function newProcessSelectedParameter({ waypoint, selectedParameter }) {
  if (waypoint.properties.reanalyse_weather) {
    if (selectedParameter === "CurrentSpeed") {
      const { coordinates } = waypoint.geometry
      const param = Math.sqrt(
        waypoint.properties.reanalyse_weather.UCUR ** 2 + waypoint.properties.reanalyse_weather.VCUR ** 2
      )
      return [coordinates[1], coordinates[0], param]
    } else if (selectedParameter === "GFS:FFG") {
      const { coordinates } = waypoint.geometry
      const param = Math.sqrt(
        waypoint.properties.reanalyse_weather.U ** 2 + waypoint.properties.reanalyse_weather.V ** 2
      )
      return [coordinates[1], coordinates[0], param]
    } else if (selectedParameter === "NWW3:WHSSW") {
      const { coordinates } = waypoint.geometry
      const param = waypoint.properties.reanalyse_weather["W_HSSW"]
      return [coordinates[1], coordinates[0], param]
    } else if (selectedParameter === "COP:SST") {
      const { coordinates } = waypoint.geometry
      const param = waypoint.properties.reanalyse_weather["SST"]
      return [coordinates[1], coordinates[0], param]
    }
  }
}

export default function RouteReanalyse({ vesselRealRoutes, switchReanalyse, selectedParameter, colorObject }) {
  const map = useMap()
  map.createPane("hotlinePane")
  map.getPane("hotlinePane").style.zIndex = 1015
  L.Hotline.renderer({ pane: "hotlinePane" })

  const layers = []

  for (const route of vesselRealRoutes) {
    let allPositions = [[]]
    let processedSelectedCoordinates = null
    for (let i = 0; i < route.points.geojson.features.length; i++) {
      processedSelectedCoordinates = newProcessSelectedParameter({
        waypoint: route.points.geojson.features[i],
        selectedParameter,
      })
      if (
        route.points.geojson.features[i].properties.legtype === "rhumbline_antimeridian" &&
        route.points.geojson.features[i + 1] &&
        route.points.geojson.features[i + 1].properties.legtype === "rhumbline_antimeridian"
      ) {
        allPositions[allPositions.length - 1].push(processedSelectedCoordinates)
        allPositions.push([])
      } else {
        allPositions[allPositions.length - 1].push(processedSelectedCoordinates)
      }
    }

    const filteredPositions = []
    allPositions.forEach((linepart, lineIndex) => {
      filteredPositions.push([])
      linepart.forEach((entry) => {
        if (entry && entry[2] !== "-" && !isNaN(entry[2])) {
          filteredPositions[lineIndex].push(entry)
        }
      })
    })

    if (switchReanalyse && filteredPositions !== null) {
      filteredPositions.map((route) => {
        layers.push(
          <LayerGroup
            key={`${selectedParameter}-${JSON.stringify(createHotlinePalette(colorObject))}-${JSON.stringify(route)}`}
          >
            return (
            <MyHotline
              key={`${selectedParameter}-${JSON.stringify(createHotlinePalette(colorObject))}-${JSON.stringify(route)}`}
              colorObject={colorObject}
              selectedParameter={selectedParameter}
              positions={route}
              map={map}
            />
            )
            <WindBarbs key="windBarbs" vesselRoutes={vesselRealRoutes} selectedParameter={selectedParameter} />
            <CurrentVectors
              key="currentVectors"
              vesselRoutes={vesselRealRoutes}
              selectedParameter={selectedParameter}
            />
            <WaveDirection key="waveDirection" vesselRoutes={vesselRealRoutes} selectedParameter={selectedParameter} />
          </LayerGroup>
        )
      })
    }
  }
  return <Box>{layers}</Box>
}
