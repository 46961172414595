import { useState, useEffect } from "react"
import PostGraphileApi from "../modules/main/PostGraphileApi"
import { useAccessContext } from "../modules/main/AccessContext"
import Helper from "../utils/Helper"

export const useFetchWaypointsGeojson = (fromPicker, toPicker, routeType, hasDateChanged, setHasDateChanged) => {
  // console.log("useFetchWaypointsGeojson hook", routeType, "\n", fromPicker.format(), "\n", toPicker.format())
  const { accessContext, setAccessContext } = useAccessContext()
  const SHIPPING_URL = accessContext.environment.shipping_api.url

  const [tritonWaypointsGeojson, setTritonWaypointsGeojson] = useState(null)
  const [loadingStates, setLoadingStates] = useState({
    real: false,
    planned: false,
    suggested: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      if (!hasDateChanged) {
        setLoadingStates((prevStates) => ({ ...prevStates, [routeType]: true }))

        // const accessToken = await Helper.checkAndRefreshToken(accessContext, setAccessContext)

        // if (!accessToken) {
        //   setLoadingStates((prevStates) => ({ ...prevStates, [routeType]: false }))
        //   return
        // }

        const graphqlQuery = `query MyQuery {
        tritonWaypointsGeojson(
          vesselAccountId: ${accessContext.payload.accountId}
          startDate: "${fromPicker.toISOString()}"
          endDate: "${toPicker.toISOString()}"
          selectedRouteType:"${routeType}"
        ) {
          nodes {
            geojson
            vesselName
            routeType
            routeId
          }
        }
      }`

        try {
          const startTime = performance.now() // Start time before the fetch
          const { data } = await PostGraphileApi.fetchRequestWithTokenRefresh(
            SHIPPING_URL,
            graphqlQuery,
            accessContext,
            setAccessContext,
            ".loadGraphql() error="
          )
          const endTime = performance.now() // End time after the fetch
          // console.log(`fetchRequest ${routeType} took ${endTime - startTime} ms`);

          setTritonWaypointsGeojson(data)
          setLoadingStates((prevStates) => ({ ...prevStates, [routeType]: false }))
          setHasDateChanged(false)
        } catch (error) {
          console.error("An error occurred:", error)
          setLoadingStates((prevStates) => ({ ...prevStates, [routeType]: false }))
        }
      }
    }
    fetchData()
  }, [hasDateChanged])

  return { tritonWaypointsGeojson, loadingState: loadingStates[routeType] }
}
