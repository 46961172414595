import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { TextField, Button, Typography, Grid, Card, CardContent } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import loginFormStyles from "./styles" // Import styles from styles.js

const LoginForm = ({ login, error = { msg: "" } }) => {
  const { t } = useTranslation()
  const [credentials, setCredentials] = useState({ name: "", password: "" })
  const theme = useTheme() // Access the theme
  const styles = loginFormStyles(theme) // Get the styles based on the theme

  const handleSubmit = (event) => {
    event.preventDefault()
    if (login) {
      login(credentials)
    }
  }

  const submitOnEnter = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event)
    }
  }

  const setFocusOnPassword = (event) => {
    if (event.key === "Enter") {
      document.getElementById("password").focus()
    }
  }

  return (
    <Grid container justifyContent="center">
      <Card sx={styles.loginForm}>
        <CardContent>
          <Grid container direction="row">
            <Grid item xs={3}>
              <img src="images/logo.png" alt="WetterWelt" width="125" height="auto" sx={styles.loginLogo} />
            </Grid>
            <Grid item xs={9}>
              <form onSubmit={handleSubmit} aria-label="login">
                <Grid container alignItems="center" direction="column">
                  <Grid item xs={12}>
                    <Grid item xs={9}>
                      <Typography variant="h3" align="center" sx={styles.loginTitle}>
                        {t("login_title")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" align="center" sx={styles.loginSubtitle}>
                      {error.msg ? error.msg : t("login_text")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      sx={styles.loginField}
                      required
                      id="userName"
                      label={t("login_label_name")}
                      value={credentials.name}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          name: e.target.value,
                        })
                      }
                      variant="outlined"
                      onKeyDown={(event) => setFocusOnPassword(event)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={styles.loginField}
                      required
                      id="password"
                      label={t("login_label_password")}
                      type="password"
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          password: e.target.value,
                        })
                      }
                      variant="outlined"
                      onKeyDown={(event) => submitOnEnter(event)}
                    />
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant="outlined"
                        type="submit"
                        // onClick={handleSubmit}
                        sx={styles.loginButton}
                      >
                        {t("login_action_login")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default LoginForm
