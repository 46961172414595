import React, { useMemo } from "react"
import ImageOverlayWithHeader from "./ImageOverlayWithHeader"

import L from "leaflet"

export default function CoastlineLayer({ coastlineUrl, coastlineKey, onLoad, latlngMapBounds, MAX_LATITUDE }) {
  const memoizedCoastline = useMemo(() => {
    return (
      <ImageOverlayWithHeader
        key={coastlineKey}
        url={coastlineUrl}
        bounds={
          new L.LatLngBounds(
            [Math.max(latlngMapBounds.getSouth(), -MAX_LATITUDE), latlngMapBounds.getWest()],
            [Math.min(latlngMapBounds.getNorth(), MAX_LATITUDE), latlngMapBounds.getEast()]
          )
        }
        opacity={1}
        zIndex={1005}
        eventHandlers={{
          load: onLoad,
        }}
      />
    )
  }, [coastlineUrl, coastlineKey])

  return memoizedCoastline
}
