import React, { useState, createContext } from "react"

// Default access context structure
const defaultAccess = {
  loggedIn: false,
  status: undefined,
  token: undefined,
  payload: undefined,
  configuration: undefined,
  environment: undefined,
  pages: [],
}

// Create AccessContext with the default structure
const AccessContext = createContext({
  accessContext: defaultAccess,
  setAccessContext: () => {}, // Placeholder for the function to update context
})

// AccessContext provider
export function AccessContextProvider(props) {
  const [accessContext, setAccessContext] = useState({
    ...defaultAccess,
    ...props.values,
  })

  return <AccessContext.Provider value={{ accessContext, setAccessContext }}>{props.children}</AccessContext.Provider>
}

// Custom hook to use access context in components
export const useAccessContext = () => React.useContext(AccessContext)
