import * as React from "react"
import { useState, useEffect } from "react"
import { useAccessContext } from "../../modules/main/AccessContext"

import { GeoJSON } from "react-leaflet"

import PostGraphileApi from "../../modules/main/PostGraphileApi"

export default function Highrisk({ toogleButtonsOnOff }) {
  const { accessContext, setAccessContext } = useAccessContext()
  const GEO_URL = accessContext.environment.geo_api.url
  const [highriskGeojsons, setHighriskGeojsons] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const graphqlQuery = `query MyQuery { highriskGeojsons { nodes { geojson } } }`

      try {
        const { data } = await PostGraphileApi.fetchRequestWithTokenRefresh(
          GEO_URL,
          graphqlQuery,
          accessContext,
          setAccessContext,
          ".loadGraphql() error="
        )
        setHighriskGeojsons(data)
      } catch (error) {
        console.error("highRisk.useEffect() error", error)
      }
    }

    fetchData()
  }, [])

  return !toogleButtonsOnOff.includes("HighRisk") || highriskGeojsons === null ? null : (
    <GeoJSON // extends FeatureGroup
      interactive={true} // keine mouse events
      transparent={true} // hintergrund zeichnen bei alpha-transparenz-bereichen dieses bildes
      data={highriskGeojsons.highriskGeojsons.nodes[0].geojson.features}
      style={{ fillColor: "red", color: "red", weight: 2 }} // style alternative 2
      onEachFeature={(feature, layer) => {
        layer.bindPopup("<div>Highrisk Area: " + feature.properties.name + "</div") // interactive={false} -> inaktiv
      }}
    />
  )
}
