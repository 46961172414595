import * as React from "react"
import { useState, useEffect } from "react"
import { useAccessContext } from "../../modules/main/AccessContext"

import { GeoJSON } from "react-leaflet"

import PostGraphileApi from "../../modules/main/PostGraphileApi"

export default function Eca({ toogleButtonsOnOff }) {
  const { accessContext, setAccessContext } = useAccessContext()
  const GEO_URL = accessContext.environment.geo_api.url
  const [ecaGeojsons, setEcaGeojsons] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const graphqlQuery = `query MyQuery { ecaGeojsons { nodes { geojson } } }`

      try {
        const { data } = await PostGraphileApi.fetchRequestWithTokenRefresh(
          GEO_URL,
          graphqlQuery,
          accessContext,
          setAccessContext,
          ".loadGraphql() error="
        )
        setEcaGeojsons(data)
      } catch (error) {
        console.error("Eca.useEffect() error", error)
      }
    }

    fetchData()
  }, [])

  return !toogleButtonsOnOff.includes("ECA") || ecaGeojsons === null ? null : (
    <GeoJSON // extends FeatureGroup
      interactive={true} // keine mouse events
      transparent={true} // hintergrund zeichnen bei alpha-transparenz-bereichen dieses bildes
      data={ecaGeojsons.ecaGeojsons.nodes[0].geojson.features}
      style={{ fillColor: "green", color: "green", weight: 2 }} // style alternative 2
      onEachFeature={(feature, layer) => {
        layer.bindPopup("<div>ECA Area: " + feature.properties.name + "</div") // interactive={false} -> inaktiv
      }}
    />
  )
}
