import React, { useEffect, useState } from "react"
import { ImageOverlay } from "react-leaflet"
import { useAccessContext } from "../../modules/main/AccessContext"
import PostGraphileApi from "../../modules/main/PostGraphileApi"

const ImageOverlayWithHeader = ({ url, bounds, onLoad, ...options }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const { accessContext, setAccessContext } = useAccessContext()

  useEffect(() => {
    const fetchImage = async () => {
      try {
        // Ensure the token is valid and refresh if necessary
        const accessToken = await PostGraphileApi.checkAndRefreshToken(accessContext, setAccessContext)
        if (!accessToken) {
          throw new Error("Failed to get access token")
        }

        const response = await fetch(url, {
          headers: { Authorization: "Bearer " + accessToken },
        })

        if (!response.ok) {
          throw new Error("Error fetching image: " + response.statusText)
        }

        const blob = await response.blob()
        const objectURL = URL.createObjectURL(blob)
        setImageUrl(objectURL)
      } catch (error) {
        console.error("Error fetching image:", error)
      }
    }

    fetchImage()
  }, [url, accessContext])

  if (!imageUrl) return null

  return (
    <ImageOverlay
      url={imageUrl}
      bounds={bounds}
      eventHandlers={{
        load: onLoad,
      }}
      {...options}
    />
  )
}

export default ImageOverlayWithHeader
