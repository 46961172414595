import * as React from "react"
import { useState, useEffect } from "react"
import { useAccessContext } from "../../modules/main/AccessContext"

import { GeoJSON, useMap } from "react-leaflet"
import L from "leaflet"

import Helper from "../../utils/Helper" //.js';
import PostGraphileApi from "../../modules/main/PostGraphileApi"

export default function Port({ toogleButtonsOnOff, zoomLevel, dragged, resized }) {
  const { accessContext, setAccessContext } = useAccessContext()

  const GEO_URL = accessContext.environment.geo_api.url

  const map = useMap()
  // ohne useEffect():
  function filterViewportBoundingbox(map, features) {
    //console.log(map.getBounds());
    const filterFeatures = features.filter((feature) => {
      return map.getBounds().contains([feature.geometry.coordinates[1], feature.geometry.coordinates[0]])
    })
    //console.log(filterFeatures)
    return filterFeatures
  }

  function getTonnageThreshold(zoomLevel) {
    const zoomTonnageThreshold = {
      3: 3000000,
      4: 1000000,
      5: 100000,
      6: 10000,
      7: 10000,
    }
    const minKey = Math.min(...Object.keys(zoomTonnageThreshold))
    const maxKey = Math.max(...Object.keys(zoomTonnageThreshold))
    let tonnageThreshold = zoomTonnageThreshold[zoomLevel]
    if (zoomLevel < minKey) {
      tonnageThreshold = zoomTonnageThreshold[minKey]
    } else if (zoomLevel > maxKey) {
      tonnageThreshold = 0
    }
    return tonnageThreshold
  }

  const [portGeojsons, setPortGeojsons] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      const graphqlQuery = "query MyQuery { portGeojsons { nodes { geojson}}}"

      try {
        const { data } = await PostGraphileApi.fetchRequestWithTokenRefresh(
          GEO_URL,
          graphqlQuery,
          accessContext,
          setAccessContext,
          ".loadGraphql() error="
        )
        setPortGeojsons(data)
      } catch (error) {
        console.error("Port.useEffect() error", error)
      }
    }

    fetchData()
  }, [])

  return !toogleButtonsOnOff.includes("Port") || portGeojsons === null ? null : (
    <GeoJSON // extends FeatureGroup
      key={[zoomLevel, dragged, resized]} // GeoJSON Neurendern erzwingen
      interactive={false} // keine mouse events
      transparent={true} // hintergrund zeichnen bei alpha-transparenz-bereichen dieses bildes
      data={filterViewportBoundingbox(map, portGeojsons.portGeojsons.nodes[0].geojson.features)}
      style={{ fillColor: "#black", color: "black", weight: 2 }} // style alternative 2
      //eventHandlers={{
      //  click: (e) => {
      //    console.log('marker clicked='+e.latlng)
      //  },
      //}}
      onEachFeature={(feature, layer) => {
        const tonnageThreshold = getTonnageThreshold(zoomLevel)
        if (feature.properties.tonnage >= tonnageThreshold) {
          //var divicon = L.divIcon({content:'jfjfjhfhjfjh' }); // Explicitly set to null or you will default to 12x12
          //L.marker([50.505, 30.57], {icon: divicon}).addTo(map);
          layer.bindTooltip("<div>" + feature.properties.name + "</div>", {
            permanent: true,
            direction: "center",
            opacity: 0.6,
            offset: L.point(0, -20),
            className: "my_tooltip_labels_ports", // siehe app.css und https://jsfiddle.net/maphew/gtdkxj8e/7/
          })
        }
        layer.bindPopup(
          "<div>Port: " +
            feature.properties.name +
            " <br> " +
            feature.geometry.coordinates +
            " <br> " +
            Helper.ConvertDDToDMS(feature.geometry.coordinates) +
            " <br> " +
            feature.properties.tonnage +
            "</div>"
        )
      }}
      pointToLayer={(feature, latlon) => {
        var featuresLayer = L.circle(latlon, {
          radius: 500,
          opacity: 1, // border
        })
        return featuresLayer
      }} // spezialfunktion nur für (geojson-)punkte, radius in m
    />
  )
}
