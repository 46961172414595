// DrawerList.js
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import Divider from "@mui/material/Divider"
import { Box, Button } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Tooltip from "@mui/material/Tooltip"

import SettingsIcon from "@mui/icons-material/Settings"
import LayersIcon from "@mui/icons-material/Layers"
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat"
import AirIcon from "@mui/icons-material/Air"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import LogoutIcon from "@mui/icons-material/Logout"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import logo from "../../assets/images/WetterWeltIcon.png"
import AltRouteIcon from "@mui/icons-material/AltRoute"

import { alpha, styled } from "@mui/material/styles"
import { blue, red, green, yellow } from "@mui/material/colors"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

import ToggleButtonsHudLayers from "../togglebuttons/ToggleButtonsHudLayers"
import ToggleButtonsGeoVectorLayers from "../togglebuttons/ToggleButtonsGeoVectorLayers"
import ToggleButtonsVesselLayers from "../togglebuttons/ToggleButtonsVesselLayers"
import ToggleButtonsVoyagePlanner from "../togglebuttons/ToggleButtonsVoyagePlaner"
import ToggleButtonsWMSLayers from "../togglebuttons/ToggleButtonsWMSLayers"

import GeolocationWatcher from "./GeolocationWatcher"
import Helper from "../../utils/Helper"

const Circle = styled("div")(({ theme, active, color }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 20,
  height: 20,
  borderRadius: "50%",
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[2],
  backgroundColor: active ? color : theme.palette.background.default, // Set the dynamic color when active
}))

const ColoredSwitch = styled(Switch)(({ theme, color }) => ({
  "& .MuiSwitch-switchBase": {
    color: color,
    "&:hover": {
      backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: color,
    "&:hover": {
      backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: color, // Set track to passed color when checked
  },
}))

const BlueSwitch = (props) => <ColoredSwitch {...props} color={blue[600]} />
const YellowSwitch = (props) => <ColoredSwitch {...props} color={yellow[600]} />
const RedSwitch = (props) => <ColoredSwitch {...props} color={red[600]} />
const GreenSwitch = (props) => <ColoredSwitch {...props} color={green[600]} />

export default function DrawerList({
  tritonRealWaypointsGeojson,
  vesselPlanRoutes,
  toogleButtonsOnOff,
  setToogleButtonsOnOff,
  handleToogleButtonsOnOff,
  setCenterLatLonMap,
  markedVesselNames,
  setMarkedVesselNames,
  switchReported,
  setSwitchReported,
  switchPlan,
  setSwitchPlan,
  switchSuggest,
  setSwitchSuggest,
  switchForecast,
  setSwitchForecast,
  switchReanalyse,
  setSwitchReanalyse,
  fromPicker,
  setFromPicker,
  toPicker,
  setToPicker,
  hasDateChanged,
  setHasDateChanged,
  openDrawer,
  setOpenDrawer,
  drawerWidth,
  openHudList,
  setOpenHudList,
  openGisList,
  setOpenGisList,
  openVesselList,
  setOpenVesselList,
  openForecastList,
  openVoyagePlanner,
  setOpenVoyagePlanner,
  setOpenForecastList,
  openWMSList,
  setOpenWMSList,
  openGeolocationList,
  setOpenGeolocationList,
  logout,
  appBarHeight,
  fetchingReported,
  fetchingForecast,
  fetchingReanalyse,
  voyagePlannerPoints,
  setVoyagePlannerPoints,
  switchVoyagePlanner,
  setSwitchVoyagePlanner,
  voyageActive,
  setVoyageActive,
  geoServerCapabilities,
  checkedWMSLayers,
  setCheckedWMSLayers,
  setPathfinderRoutes,
  calculatorDepartureTime,
  setCalculatorDepartureTime,
  calculatorBasespeed,
  setCalculatorBasespeed,
  etaResponse,
  setEtaResponse,
}) {
  // const [loadingForecast, setLoadingForecast] = useState(false)

  const Icon = ({ switchValue, fetchingType, color }) => (
    <Circle active={switchValue && !fetchingType} color={color}>
      {fetchingType && <CircularProgress size={14} style={{ color: "#264a7c" }} thickness={6} />}
    </Circle>
  )

  const IconBlue = (props) => <Icon {...props} color={blue[600]} />
  const IconYellow = (props) => <Icon {...props} color={yellow[600]} />
  const IconRed = (props) => <Icon {...props} color={red[600]} />
  const IconGreen = (props) => <Icon {...props} color={green[600]} />

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }

  const handleHudList = () => {
    setOpenHudList(!openHudList)
    if (!openDrawer) {
      handleDrawerOpen()
    }
  }

  const handleGisList = () => {
    setOpenGisList(!openGisList)
    if (!openDrawer) {
      handleDrawerOpen()
    }
  }

  const handleVesselList = () => {
    setOpenVesselList(!openVesselList)
    if (!openDrawer) {
      handleDrawerOpen()
    }
  }

  const handleVoyagePlanner = () => {
    // Toggling the state of the "VoyagePlanner" in the button click handler
    setToogleButtonsOnOff((prevState) => {
      if (!openVoyagePlanner) {
        // If "VoyagePlanner" is not in the list, add it
        return [...prevState, "VoyagePlanner"]
      } else {
        // If it's already in the list, remove it
        return prevState.filter((item) => item !== "VoyagePlanner")
      }
    })

    // Updating the state that controls the "VoyagePlanner" component
    setSwitchVoyagePlanner(!openVoyagePlanner)

    // Toggle the drawer state
    setOpenVoyagePlanner(!openVoyagePlanner)
    setVoyageActive(!voyageActive)

    // If the drawer is closed, open it
    if (!openDrawer) {
      handleDrawerOpen()
    }
  }

  const handleForecastList = () => {
    setOpenForecastList(!openForecastList)
    if (!openDrawer) {
      handleDrawerOpen()
    }
  }

  const handleWMSList = () => {
    setOpenWMSList(!openWMSList)
    if (!openDrawer) {
      handleDrawerOpen()
    }
  }

  const handleGeolocationList = () => {
    setOpenGeolocationList(!openGeolocationList)
    if (!openDrawer) {
      handleDrawerOpen()
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: `calc(100vh - ${appBarHeight}px)` }}>
      <Box sx={{ flexGrow: 1 }}>
        <List
          sx={{
            width: "100%",
            minWidth: drawerWidth,
            maxWidth: drawerWidth,
            bgcolor: "background.paper",
            pt: 0,
            pb: 0,
          }}
          component="nav"
          aria-labelledby="drawer-list"
        >
          <ListItemButton onClick={handleVesselList}>
            <ListItemIcon>
              <Tooltip title="Vessel Filters" placement="right">
                <DirectionsBoatIcon sx={{ color: "#004f7d" }} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Vessels" />
            {openVesselList ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openVesselList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                // style={{ marginTop: "10px", marginBottom: "10px" }}
                sx={{
                  "&.Mui-focusVisible": { backgroundColor: "transparent" },
                }}
              >
                <ToggleButtonsVesselLayers
                  tritonRealWaypointsGeojson={tritonRealWaypointsGeojson}
                  vesselPlanRoutes={vesselPlanRoutes}
                  toogleButtonsOnOff={toogleButtonsOnOff}
                  handleToogleButtonsOnOff={handleToogleButtonsOnOff}
                  setCenterLatLonMap={setCenterLatLonMap}
                  markedVesselNames={markedVesselNames}
                  setMarkedVesselNames={setMarkedVesselNames}
                  setSwitchReported={setSwitchReported}
                  setSwitchPlan={setSwitchPlan}
                  setSwitchSuggest={setSwitchSuggest}
                  setSwitchForecast={setSwitchForecast}
                  setSwitchReanalyse={setSwitchReanalyse}
                />
              </ListItem>
              <ListItem>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={fromPicker}
                    onChange={(newValue) => {
                      setHasDateChanged(true)
                      if (
                        !isNaN(new Date(newValue).getTime()) &&
                        (toPicker === null || new Date(newValue) <= new Date(toPicker))
                      ) {
                        setFromPicker(newValue)
                      }
                    }}
                    maxDate={toPicker}
                    slotProps={{
                      textField: {
                        style: { width: "241.719px" },
                        error: fromPicker && isNaN(Date.parse(fromPicker)),
                        helperText: fromPicker && isNaN(Date.parse(fromPicker)) && "Invalid date format",
                      },
                    }}
                  />
                </LocalizationProvider>
              </ListItem>
              <ListItem>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={toPicker}
                    onChange={(newValue) => {
                      setHasDateChanged(true)
                      if (
                        !isNaN(new Date(newValue).getTime()) &&
                        (fromPicker === null || new Date(newValue) >= new Date(fromPicker))
                      ) {
                        setToPicker(newValue)
                      }
                    }}
                    minDate={fromPicker}
                    slotProps={{
                      textField: {
                        style: { width: "241.719px" },
                        error: toPicker && isNaN(Date.parse(toPicker)),
                        helperText: toPicker && isNaN(Date.parse(toPicker)) && "Invalid date format",
                      },
                    }}
                  />
                </LocalizationProvider>
              </ListItem>
              <ListItem>
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    sx={{ backgroundColor: "#004f7d", textTransform: "none" }}
                    variant="contained"
                    disabled={!hasDateChanged}
                    onClick={() => {
                      setHasDateChanged(false)
                      // console.log("button", hasDateChanged)
                    }}
                  >
                    Apply Dates
                  </Button>
                </div>
              </ListItem>
              <ListItem>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      // <div style={{ display: "flex", alignItems: "center" }}>
                      <GreenSwitch
                        checkedIcon={<IconGreen switchValue={switchPlan} fetchingType={fetchingReported} />}
                        icon={<IconGreen switchValue={switchPlan} fetchingType={fetchingReported} />}
                        checked={switchPlan}
                        onChange={(event) => {
                          setSwitchPlan(event.target.checked)
                          if (!event.target.checked) {
                            setSwitchForecast(false)
                          }
                          const bottomControls = document.querySelectorAll(".leaflet-bottom")
                          bottomControls.forEach(function (control) {
                            if (event.target.checked && !switchReanalyse) {
                              control.style.bottom = "52px" // Adjust when the switch is turned on
                            } else if (!switchReported) {
                              control.style.bottom = "0px" // Reset when the switch is turned off
                            }
                          })
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={!Helper.planRouteExists(markedVesselNames, vesselPlanRoutes)}
                      />
                      //   <img src={logo} alt="Triton" style={{ height: "22px", paddingRight: "10px" }} />
                      // </div>
                    }
                    label="Planned Route"
                  />
                  <Tooltip
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={logo} alt="Triton" style={{ height: "40px", marginRight: "8px" }} />
                        <div>
                          Planned waypoints of current voyage.
                          <br />
                          Disabled if no active weather routing available. Data provided by WetterWelt GmbH.
                        </div>
                      </div>
                    }
                  >
                    <InfoIcon color="#004f7d" style={{ marginLeft: "39px" }} />
                  </Tooltip>
                </div>
              </ListItem>
              <ListItem>
                {" "}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      // <div style={{ display: "flex", alignItems: "center" }}>
                      <RedSwitch
                        checkedIcon={<IconRed switchValue={switchForecast} fetchingType={fetchingForecast} />}
                        icon={<IconRed switchValue={switchForecast} fetchingType={fetchingForecast} />}
                        checked={switchForecast}
                        onChange={(event) => {
                          setSwitchForecast(event.target.checked)
                          const bottomControls = document.querySelectorAll(".leaflet-bottom")
                          bottomControls.forEach(function (control) {
                            if (event.target.checked) {
                              control.style.bottom = "52px" // Adjust when the switch is turned on
                            } else {
                              control.style.bottom = "52px" // Reset when the switch is turned off
                            }
                          })
                        }}
                        // value="checkedA"
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={(!switchPlan && !switchSuggest) || markedVesselNames.length === 0}
                      />
                      //   <img src={logo} alt="Triton" style={{ height: "22px", paddingRight: "10px" }} />
                      // </div>
                    }
                    label="Weather Forecast"
                  />
                  <Tooltip
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={logo} alt="Triton" style={{ height: "40px", marginRight: "8px" }} />
                        <div>
                          Forecast weather along current voyage.
                          <br />
                          Disabled if no planned Route selected or available. Data provided by WetterWelt GmbH.
                        </div>
                      </div>
                    }
                  >
                    <InfoIcon color="#004f7d" style={{ marginLeft: "18px" }} />
                  </Tooltip>
                </div>
              </ListItem>
              <ListItem>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <BlueSwitch
                        id="myBlueSwitch"
                        checkedIcon={<IconBlue switchValue={switchReported} fetchingType={fetchingReported} />}
                        icon={<IconBlue switchValue={switchReported} fetchingType={fetchingReported} />}
                        checked={switchReported}
                        onChange={(event) => {
                          setSwitchReported(event.target.checked)
                          if (!event.target.checked) {
                            setSwitchReanalyse(false)
                          }
                          const bottomControls = document.querySelectorAll(".leaflet-bottom")
                          bottomControls.forEach(function (control) {
                            if (event.target.checked && !switchForecast) {
                              control.style.bottom = "52px" // Adjust when the switch is turned on
                            } else if (!switchPlan) {
                              control.style.bottom = "0px" // Reset when the switch is turned off
                            }
                          })
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    // label={<div style={{ marginLeft: "32px" }}>Reported Route</div>}
                    label="Reported Route"
                  />
                  <Tooltip title="Past track of reported waypoints including daily reports.">
                    <InfoIcon color="#004f7d" style={{ marginLeft: "31px" }} />
                  </Tooltip>
                </div>
              </ListItem>
              <ListItem>
                {" "}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      // <div style={{ display: "flex", alignItems: "center" }}>
                      <YellowSwitch
                        checkedIcon={<IconYellow switchValue={switchReanalyse} fetchingType={fetchingReanalyse} />}
                        icon={<IconYellow switchValue={switchReanalyse} fetchingType={fetchingReanalyse} />}
                        checked={switchReanalyse}
                        onChange={(event) => {
                          setSwitchReanalyse(event.target.checked)
                          const bottomControls = document.querySelectorAll(".leaflet-bottom")
                          bottomControls.forEach(function (control) {
                            if (event.target.checked) {
                              control.style.bottom = "52px" // Adjust when the switch is turned on
                            } else {
                              control.style.bottom = "52px" // Reset when the switch is turned off
                            }
                          })
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={!switchReported || markedVesselNames.length === 0}
                      />
                      //   {/* <img src={logo} alt="Triton" style={{ height: "22px", paddingRight: "10px" }} />
                      // </div> */}
                    }
                    label="Weather Reanalysis"
                  />
                  <Tooltip
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={logo} alt="Triton" style={{ height: "40px", marginRight: "8px" }} />
                        <div>
                          Reanalysed weather along past track.
                          <br />
                          Disabled if no reported Route displayed. Data provided by WetterWelt GmbH.
                        </div>
                      </div>
                    }
                  >
                    <InfoIcon color="#004f7d" style={{ marginLeft: "2px" }} />
                  </Tooltip>
                </div>
              </ListItem>
              {/* <ListItem>
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    sx={{ backgroundColor: "#004f7d", textTransform: "none" }}
                    variant="contained"
                    // disabled={!fromPicker && !toPicker}
                  >
                    <AddIcon />
                    More Routes
                  </Button>
                </div>
              </ListItem> */}
              {/* <ListItemButton>
                <FormControlLabel
                  control={
                    <RedSwitch
                      checked={switchSuggest}
                      onChange={(event) => {
                        setSwitchSuggest(event.target.checked)
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Suggest"
                />
              </ListItemButton> */}
            </List>
          </Collapse>
          {/* <Divider /> */}

          <ListItemButton onClick={handleWMSList}>
            <ListItemIcon>
              <Tooltip title="Weather Forecast" placement="right">
                <AirIcon sx={{ color: "#004f7d" }} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Weather Forecast" />
            {openWMSList ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openWMSList} timeout="auto" unmountOnExit>
            <ToggleButtonsWMSLayers
              geoServerCapabilities={geoServerCapabilities}
              checkedWMSLayers={checkedWMSLayers}
              setCheckedWMSLayers={setCheckedWMSLayers}
            />
          </Collapse>
          {/* <Divider /> */}

          <ListItemButton onClick={handleVoyagePlanner}>
            <ListItemIcon>
              <Tooltip title="Voyage Planner" placement="right">
                <AltRouteIcon sx={{ color: "#004f7d" }} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Voyage Planner" />
            {openVoyagePlanner ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openVoyagePlanner} timeout="auto" unmountOnExit>
            <ToggleButtonsVoyagePlanner
              calculatorDepartureTime={calculatorDepartureTime}
              setCalculatorDepartureTime={setCalculatorDepartureTime}
              calculatorBasespeed={calculatorBasespeed}
              setCalculatorBasespeed={setCalculatorBasespeed}
              setToogleButtonsOnOff={setToogleButtonsOnOff}
              voyagePlannerPoints={voyagePlannerPoints}
              setVoyagePlannerPoints={setVoyagePlannerPoints}
              setVoyageActive={setVoyageActive}
              switchVoyagePlanner={switchVoyagePlanner}
              setSwitchVoyagePlanner={setSwitchVoyagePlanner}
              setPathfinderRoutes={setPathfinderRoutes}
              etaResponse={etaResponse}
              setEtaResponse={setEtaResponse}
            />
          </Collapse>
          {/* <Divider /> */}

          <ListItemButton onClick={handleGisList}>
            <ListItemIcon>
              <Tooltip title="GIS Layers" placement="right">
                <LayersIcon sx={{ color: "#004f7d" }} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="GIS Layers" />
            {openGisList ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openGisList} timeout="auto" unmountOnExit>
            <ListItem sx={{ color: "primary" }}>
              <ToggleButtonsGeoVectorLayers
                toogleButtonsOnOff={toogleButtonsOnOff}
                setToogleButtonsOnOff={setToogleButtonsOnOff}
              />
            </ListItem>
          </Collapse>
          {/* <Divider /> */}

          <ListItemButton onClick={handleHudList}>
            <ListItemIcon>
              <Tooltip title="Map Settings" placement="right">
                <SettingsIcon sx={{ color: "#004f7d" }} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Map Settings" />
            {openHudList ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openHudList} timeout="auto" unmountOnExit>
            <ListItem>
              <ToggleButtonsHudLayers
                toogleButtonsOnOff={toogleButtonsOnOff}
                setToogleButtonsOnOff={setToogleButtonsOnOff}
              />
            </ListItem>
          </Collapse>
          {/* <Divider /> */}

          {/* 
          <ListItemButton onClick={handleGeolocationList}>
            <ListItemIcon>
              <PublicIcon sx={{ color: "#004f7d" }} />
            </ListItemIcon>
            <ListItemText primary="Geolocation" />
            {openGeolocationList ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openGeolocationList} timeout="auto" unmountOnExit>
            <ListItemButton sx={{ pl: 4, color: "primary" }}>
              <GeolocationWatcher />
            </ListItemButton>
          </Collapse> */}
        </List>
      </Box>

      <Box sx={{ bottom: 0 }}>
        <List sx={{ pb: 0 }}>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <Tooltip title="Logout" placement="right">
                <LogoutIcon sx={{ color: "#004f7d" }} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Box>
    </Box>
  )
}
