import React, { useState, useEffect, useMemo } from "react"
import { useAccessContext } from "../../modules/main/AccessContext"
import PostGraphileApi from "../../modules/main/PostGraphileApi"

export default function WMSLegend({ legendUrl, drawerWidth, openDrawer, layerName, styleName }) {
  const [legendImage, setLegendImage] = useState(null)
  const { accessContext, setAccessContext } = useAccessContext()

  useEffect(() => {
    const fetchLegendImage = async () => {
      try {
        // Ensure the token is valid and refresh if necessary
        const accessToken = await PostGraphileApi.checkAndRefreshToken(accessContext, setAccessContext)
        if (!accessToken) {
          throw new Error("Failed to get access token")
        }

        const response = await fetch(legendUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "image/png",
          },
        })

        if (!response.ok) {
          throw new Error("Failed to fetch legend image: " + response.statusText)
        }

        const blob = await response.blob()
        const imageObjectURL = URL.createObjectURL(blob)
        setLegendImage(imageObjectURL)
      } catch (error) {
        console.error("Failed to fetch legend image:", error)
      }
    }

    fetchLegendImage()
  }, [legendUrl, accessContext])

  const memoizedLegend = useMemo(() => {
    if (!legendImage) return null
    return (
      <div
        style={{
          position: "absolute",
          top: 75,
          zIndex: 1050,
          background: "white",
          padding: "5px",
          borderRadius: "0.2rem",
          marginLeft: openDrawer ? `${drawerWidth + 10}px` : `67px`,
        }}
      >
        <p>{`${layerName}`}</p>
        <img src={legendImage} alt={`${layerName} - ${styleName} legend`} />
      </div>
    )
  }, [legendImage, drawerWidth, openDrawer, layerName, styleName])

  // Clean up the object URL when the component unmounts
  useEffect(() => {
    return () => {
      if (legendImage) {
        URL.revokeObjectURL(legendImage)
      }
    }
  }, [legendImage])

  return memoizedLegend
}
