import { LayerGroup, GeoJSON } from "react-leaflet"
import RouteLines from "../route/RouteLines"
import RoutePoints from "../route/RoutePoints"
import RouteHelper from "../../utils/routeHelper"
import { useAccessContext } from "../../modules/main/AccessContext"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(utc)
dayjs.extend(localizedFormat)

export default function PathfinderController({
  pathfinderRoutes,
  setPathfinderRoutes,
  mapBounds,
  sliderPosition,
  markedVesselNames,
  setCenterLatLonMap,
  setMarkedVesselNames,
  setOpenDrawer,
  setOpenVesselList,
  map,
  calculatorDepartureTime,
  calculatorBasespeed,
  setEtaResponse,
}) {
  if (Object.keys(pathfinderRoutes).length === 0 || !sliderPosition.label) {
    return
  }

  let convertedRoute = pathfinderRoutes
  let vessels = RouteHelper.buildVesselsGeojson({ routes: convertedRoute, sliderPosition })
  return (
    <LayerGroup key={"Pathfinder"}>
      <RoutePoints
        vesselRoutes={convertedRoute}
        setVesselRoutes={setPathfinderRoutes}
        mapBounds={mapBounds}
        calculatorDepartureTime={calculatorDepartureTime}
        calculatorBasespeed={calculatorBasespeed}
        setEtaResponse={setEtaResponse}
        setPathfinderRoutes={setPathfinderRoutes}
      />
      <RouteLines
        vesselRoutes={convertedRoute}
        setVesselRoutes={setPathfinderRoutes}
        mapBounds={mapBounds}
        calculatorDepartureTime={calculatorDepartureTime}
        calculatorBasespeed={calculatorBasespeed}
        setEtaResponse={setEtaResponse}
        setPathfinderRoutes={setPathfinderRoutes}
      />
      {vessels.map((vessel) => {
        let vesselPoints = vessel.points
        return vesselPoints.geojson.features === null || vesselPoints.geojson.features.length === null ? null : (
          <GeoJSON
            key={JSON.stringify(vesselPoints.geojson.features)}
            data={vesselPoints.geojson}
            style={{
              fillColor: RouteHelper.findRouteColor("Pathfinder"),
              color: RouteHelper.findRouteColor("Pathfinder"),
              weight: 1,
            }}
            pointToLayer={(feature, latlon) => {
              return RouteHelper.renderRotatedIcon({
                vesselPoints,
                markedVesselNames,
                setCenterLatLonMap,
                setMarkedVesselNames,
                setOpenDrawer,
                setOpenVesselList,
                map,
                handleMarkerClick: () => {},
              })
            }}
            onEachFeature={(feature, layer) => RouteHelper.applyVesselPopup({ vesselPoints, feature, layer })}
          />
        )
      })}
    </LayerGroup>
  )
}
