const environments = {
  test: {
    auth: { url: "https://uss-01.local:8080", path: "/auth" },
    sc_api: { url: "https://uss-01.local:8080/graphql/sc" },
    shipping_api: { url: "https://uss-01.local:8080/graphql/sc" },
    meteo_api: { url: "https://uss-01.local:8080/graphql/meteodb" },
    geo_api: { url: "https://uss-01.local:8080/graphql/geodb" },
    geoserver_api: { url: "http://uss-01.local:8081/geoserver/" },
    flask_api: { url: "http://uss-01.local:8081/" },
    // http immer 8081, https immer 8080 hat zertifikat problem aber läuft
  },
  local: {
    auth: { url: "https://localhost:8080", path: "/auth" },
    sc_api: { url: "https://localhost:8080/graphql/sc" },
    shipping_api: { url: "https://localhost:8080/graphql/sc" },
    meteo_api: { url: "https://localhost:8080/graphql/meteodb" },
    geo_api: { url: "https://localhost:8080/graphql/geodb" },
    geoserver_api: { url: "https://localhost:8080/geoserver/" },
    flask_api: { url: "https://localhost:8080/" },
    // http immer 8081, https immer 8080 hat zertifikat problem aber läuft
  },
  direct: {
    auth: { url: "https://uss-01.local:8080", path: "/auth" },
    sc_api: { url: "http://grib-gis-01.local/service-center/graphql" },
    shipping_api: { url: "http://grib-gis-01.local/service-center/graphql" },
    meteo_api: { url: "http://grib-gis-01.local/meteodb-wod/graphql" },
    geo_api: { url: "http://grib-gis-01.local/geodb/graphql" },
    geoserver_api: { url: "https://geoserver.wetterwelt.de/geoserver/" },
    flask_api: { url: "http://192.168.10.237:5000/" },
  },
  prod: {
    auth: { url: "https://api.wetterwelt.de", path: "/auth" },
    sc_api: { url: "https://api.wetterwelt.de/graphql/sc" },
    shipping_api: { url: "https://api.wetterwelt.de/graphql/shippingdb" },
    meteo_api: { url: "https://api.wetterwelt.de/graphql/meteodb" },
    geo_api: { url: "https://api.wetterwelt.de/graphql/geodb" },
    geoserver_api: { url: "https://api.wetterwelt.de/geoserver/" },
    flask_api: { url: "https://api.wetterwelt.de/" },
  },
}

// gewünschtes environment exportieren:
export default environments.prod
