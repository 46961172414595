import React, { useState, useEffect, useCallback, useRef } from "react"
import { useMap } from "react-leaflet"
import L from "leaflet"
import ImageOverlayWithHeader from "./ImageOverlayWithHeader"
import WMSLegend from "./WMSLegend"
import CoastlineLayer from "./CoastlineLayer"
import { useAccessContext } from "../../modules/main/AccessContext"

const MAX_LATITUDE = 85.06

function getLegendGraphicUrl(baseUrl, layerName, styleName, format = "image/png", width = 20, height = 1) {
  const url = new URL(baseUrl)
  url.searchParams.append("REQUEST", "GetLegendGraphic")
  url.searchParams.append("FORMAT", format)
  url.searchParams.append("LAYER", layerName)
  url.searchParams.append("STYLE", styleName)
  url.searchParams.append("WIDTH", width)
  url.searchParams.append("HEIGHT", height)
  return url.toString()
}

function getCoastlineUrl(GEOSERVER_URL, mapSize, adjustedHeight, mercatorMapBounds) {
  const url = new URL(`${GEOSERVER_URL}ne/wms?service=WMS`)
  url.searchParams.append("REQUEST", "GetMap")
  url.searchParams.append("FORMAT", "image/png")
  url.searchParams.append("TRANSPARENT", "True")
  url.searchParams.append("LAYERS", "land_sea_chart_3857")
  url.searchParams.append("STYLES", "coastline")
  url.searchParams.append("WIDTH", mapSize.x)
  url.searchParams.append("HEIGHT", adjustedHeight)
  url.searchParams.append("CRS", "EPSG:3857")
  url.searchParams.append("BBOX", mercatorMapBounds.join(","))
  return url.toString()
}

function toMercator(coords) {
  const R = 6378137 // Earth's radius in meters
  const [lng, lat] = coords
  const x = (R * lng * Math.PI) / 180
  const y = R * Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360))
  return [x, y]
}

function getCurrentMapBounds(bounds) {
  const west = bounds.getWest()
  const east = bounds.getEast()
  const south = bounds.getSouth()
  const north = bounds.getNorth()

  const clampedSouth = Math.max(south, -MAX_LATITUDE)
  const clampedNorth = Math.min(north, MAX_LATITUDE)

  const westSouth = toMercator([west, clampedSouth])
  const eastNorth = toMercator([east, clampedNorth])

  const westC = Math.min(westSouth[0], eastNorth[0])
  const eastC = Math.max(westSouth[0], eastNorth[0])
  const southC = Math.min(westSouth[1], eastNorth[1])
  const northC = Math.max(westSouth[1], eastNorth[1])

  return [westC, southC, eastC, northC]
}

function getAdjustedHeight(bounds, clampedBounds, mapSize) {
  const westSouth = toMercator([bounds.getWest(), bounds.getSouth()])
  const eastNorth = toMercator([bounds.getEast(), bounds.getNorth()])
  const originalHeight = eastNorth[1] - westSouth[1]

  const clampedHeight = clampedBounds[3] - clampedBounds[1]

  const heightRatio = clampedHeight / originalHeight
  const adjustedHeight = mapSize.y * heightRatio

  return Math.round(adjustedHeight)
}

export default function GeoServerController({ sliderPosition, checkedWMSLayers, drawerWidth, openDrawer, resized }) {
  const { accessContext } = useAccessContext()
  const GEOSERVER_URL = accessContext.environment.geoserver_api.url

  const [layers, setLayers] = useState([]) // Current layers (both old and new during transition)
  const [legends, setLegends] = useState([]) // Current legends
  const [loadingCount, setLoadingCount] = useState(0) // Number of layers still loading

  const previousLayersRef = useRef([]) // Ref to store previous layers
  const layerVersionRef = useRef(0) // Version counter for layers

  const map = useMap()
  const formattedTimestamp = sliderPosition.label.replace(/\+00:00$/, ".000Z")

  const updateLayers = useCallback(() => {
    // Increment the layer version
    layerVersionRef.current += 1
    const currentLayerVersion = layerVersionRef.current

    const mapSize = map.getSize()
    const latlngMapBounds = map.getBounds()
    const mercatorMapBounds = getCurrentMapBounds(latlngMapBounds)
    const clampedBounds = getCurrentMapBounds(latlngMapBounds)
    const adjustedHeight = getAdjustedHeight(latlngMapBounds, clampedBounds, mapSize)

    const newLegends = []
    const newLayers = []

    let newLoadingCount = 0 // Initialize loading count for new layers

    Object.entries(checkedWMSLayers).forEach(([model, parameters]) => {
      Object.entries(parameters).forEach(([parameter, layerStyles]) => {
        Object.entries(layerStyles).forEach(([layer, styles]) => {
          styles.forEach((style) => {
            const modelUrl = `${GEOSERVER_URL}${model}/wms`

            let zIndex, opacity, legendUrl, coastlineUrl
            if (style.includes("_PColor")) {
              zIndex = 1001
              opacity = 1
              legendUrl = getLegendGraphicUrl(
                modelUrl,
                `${parameter}_RColor`,
                style.replace("_PColor", "_RColor_Inter")
              )
              coastlineUrl = getCoastlineUrl(GEOSERVER_URL, mapSize, adjustedHeight, mercatorMapBounds)
            } else if (style.includes("_RColor")) {
              zIndex = 1001
              opacity = 1
              legendUrl = getLegendGraphicUrl(modelUrl, layer, style.replace("_RColor", "_RColor_Inter"))
              coastlineUrl = getCoastlineUrl(GEOSERVER_URL, mapSize, adjustedHeight, mercatorMapBounds)
            } else if (style.includes("_Contour")) {
              zIndex = 1002
              opacity = 1
            } else {
              zIndex = 1003
              opacity = 1
            }

            // Update wmsKey to include version
            const wmsKey = `${model}_${layer}_${style}_${formattedTimestamp}_${mercatorMapBounds}_v${currentLayerVersion}`

            const imageUrl = `${modelUrl}?service=WMS&request=GetMap&layers=${layer}&styles=${style}&format=image/png&transparent=true&version=1.3.0&width=${
              mapSize.x
            }&height=${adjustedHeight}&crs=EPSG:3857&bbox=${mercatorMapBounds.join(",")}&time=${formattedTimestamp}`

            // Increase the loading count for each new layer
            newLoadingCount += 1

            const handleLayerLoad = () => {
              setLoadingCount((prevCount) => prevCount - 1)
            }

            newLayers.push(
              <div className={`layer_${wmsKey}`} key={`layer_${wmsKey}`}>
                <ImageOverlayWithHeader
                  key={`image_${wmsKey}`}
                  url={imageUrl}
                  bounds={
                    new L.LatLngBounds(
                      [Math.max(latlngMapBounds.getSouth(), -MAX_LATITUDE), latlngMapBounds.getWest()],
                      [Math.min(latlngMapBounds.getNorth(), MAX_LATITUDE), latlngMapBounds.getEast()]
                    )
                  }
                  opacity={opacity}
                  zIndex={zIndex}
                  onLoad={handleLayerLoad} // Attach the onLoad handler
                />
              </div>
            )

            if (style.includes("_RColor") || style.includes("_PColor")) {
              // Update coastlineKey to include version
              const coastlineKey = `coastline_layer_${mercatorMapBounds}_v${currentLayerVersion}`

              // Increase loading count for the coastline layer
              newLoadingCount += 1

              const handleCoastlineLoad = () => {
                setLoadingCount((prevCount) => prevCount - 1)
              }

              coastlineUrl = getCoastlineUrl(GEOSERVER_URL, mapSize, adjustedHeight, mercatorMapBounds)

              newLayers.push(
                <div key={`layer_${coastlineKey}`}>
                  <CoastlineLayer
                    key={`coastline_${coastlineKey}`}
                    coastlineUrl={coastlineUrl}
                    coastlineKey={coastlineKey}
                    latlngMapBounds={latlngMapBounds}
                    MAX_LATITUDE={MAX_LATITUDE}
                    onLoad={handleCoastlineLoad}
                  />
                </div>
              )

              const legendKey = `legend_${layer}_${style}_v${currentLayerVersion}`
              newLegends.push(
                <div key={legendKey}>
                  <WMSLegend
                    key={`legend_${legendKey}`}
                    legendUrl={legendUrl}
                    drawerWidth={drawerWidth}
                    openDrawer={openDrawer}
                    layerName={`${model}:${parameter}`}
                    styleName={style}
                  />
                </div>
              )
            }
          })
        })
      })
    })

    // Update the loading count state
    setLoadingCount(newLoadingCount)

    // Store the current layers as old layers
    const oldLayers = previousLayersRef.current

    // Update the layers to include both old and new layers
    setLayers([...oldLayers, ...newLayers])
    setLegends(newLegends)

    // Update the ref to hold the new layers
    previousLayersRef.current = newLayers
  }, [GEOSERVER_URL, checkedWMSLayers, formattedTimestamp, drawerWidth, openDrawer, map])

  useEffect(() => {
    updateLayers()
  }, [sliderPosition, checkedWMSLayers, updateLayers, resized])

  useEffect(() => {
    const onMoveEnd = () => {
      updateLayers()
    }
    map.on("moveend", onMoveEnd)
    return () => {
      map.off("moveend", onMoveEnd)
    }
  }, [map, updateLayers])

  // When loadingCount reaches zero, all new layers have loaded
  useEffect(() => {
    if (loadingCount === 0) {
      // Remove old layers (layers before the new ones were added)
      setLayers((currentLayers) => {
        // Keep only the new layers
        const newLayers = previousLayersRef.current
        return newLayers
      })
    }
  }, [loadingCount])

  return (
    <>
      {layers}
      <div className="legends-container">{legends}</div>
    </>
  )
}
